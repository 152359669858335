import { Component, For, Show } from 'solid-js';
import IconTempProperty from '~/assets/tempProperty.png';
import { Skeleton } from '~/components/common/Skeleton';
import { Checkbox } from '~/components/ui';
import { PresentableUnit } from '~/types/PresentableUnit';

interface ImageGridProps {
  units?: PresentableUnit[];
  onSelectionChange?: (selectedIds: string[]) => void;

  loading?: boolean;
}

export const UnitSkeleton: Component = () => {
  return (
    <div class="flex h-20 cursor-pointer items-center gap-4 rounded-lg border bg-white p-4 shadow-md">
      <Skeleton class="size-5" />
      <div class="flex grow items-center gap-2">
        <Skeleton class="size-14 h-14" />
        <div class="flex grow flex-col gap-2">
          <Skeleton class="w-1/3" />
          <Skeleton class="w-2/3" />
        </div>
      </div>
    </div>
  );
};

export const UnitsGrid: Component<ImageGridProps> = (props) => {
  const toggleSelection = (id: string, event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!props.units) {
      return;
    }

    const selectedIds: string[] = props.units
      .map((unit) => {
        if (unit.id === id) {
          return unit.isSelected ? undefined : unit.id;
        } else {
          return unit.isSelected ? unit.id : undefined;
        }
      })
      .filter((unitId): unitId is string => Boolean(unitId));

    props.onSelectionChange?.(selectedIds);
  };

  return (
    <div class="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
      <Show
        when={!props.loading}
        fallback={Array.from({ length: 3 }, () => (
          <UnitSkeleton />
        ))}>
        <For each={props.units}>
          {(unit) => (
            <div
              id="rental-unit-toggle"
              class="flex h-20 cursor-pointer items-center gap-4 rounded-lg border bg-white p-4 shadow-md"
              onClick={(event) => toggleSelection(unit.id, event)}>
              <Checkbox checkedValue={unit.isSelected} />
              <div class="flex min-w-0 items-center gap-2">
                <img
                  src={unit.images[0] || IconTempProperty}
                  alt={`Unit ${unit.name}`}
                  class="size-14 object-cover"
                  onError={(e) => (e.currentTarget.src = IconTempProperty)}
                />
                <div class="flex min-w-0 grow flex-col">
                  <div class="font-semi-bold min-w-0 truncate text-base">{unit.name}</div>
                  <div class="min-w-0 truncate text-xs text-gray-500">{unit.address}</div>
                </div>
              </div>
            </div>
          )}
        </For>
      </Show>
    </div>
  );
};
