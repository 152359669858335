import { EventEmitter } from './eventEmitter';

export abstract class UseCase<T> {
  protected static state: any;
  protected eventEmitter?: EventEmitter;
  protected onProgress?: (progress: number) => void;

  private static runningUseCases = new Map<string, Promise<void>>();
  private static initialStatePromise?: Promise<void>;

  constructor(eventEmitter?: EventEmitter, onProgress?: (progress: number) => void) {
    this.eventEmitter = eventEmitter;
    this.onProgress = onProgress;
  }

  private fnv1aHash(str: string): number {
    let hash = 0x811c9dc5;
    for (let i = 0; i < str.length; i++) {
      hash ^= str.charCodeAt(i);
      hash = (hash * 0x01000193) >>> 0;
    }
    return hash;
  }

  private getExecutionKey(params: any): string {
    const paramsKey = JSON.stringify(params);
    const classHash = this.fnv1aHash(this.constructor.toString());
    return `${classHash}_${paramsKey}`;
  }

  public async execute(params?: any): Promise<void> {
    if (!this.isAppStateInitialized()) {
      if (!UseCase.initialStatePromise) {
        UseCase.initialStatePromise = this.initializeState().then((state) => {
          UseCase.state = state;
        });
      }
      try {
        await UseCase.initialStatePromise;
      } finally {
        UseCase.initialStatePromise = undefined;
      }
    }
    const executionKey = this.getExecutionKey(params);
    if (UseCase.runningUseCases.has(executionKey)) {
      await UseCase.runningUseCases.get(executionKey);
      return;
    }
    const executionPromise = this.runWithUpdate(() => this.runLogic(params));
    UseCase.runningUseCases.set(executionKey, executionPromise);
    try {
      await executionPromise;
    } finally {
      UseCase.runningUseCases.delete(executionKey);
    }
  }

  protected getState(): T {
    return UseCase.state;
  }

  protected navigate(url: string) {
    this.eventEmitter?.emitNavigation(url);
  }

  protected abstract isAppStateInitialized(): boolean;
  protected abstract runLogic(params: any): Promise<void>;
  protected abstract initializeState(): Promise<T>;

  protected async runWithUpdate(functionToRun: () => Promise<void>): Promise<void> {
    await functionToRun();
    this.eventEmitter?.emitStateChange(UseCase.state);
  }
}
