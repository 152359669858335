import IconCheckEmail from '~/assets/icons/checkEmail.png';
import { DONE, RENTAL_APPLICATION_NOTIFICATION, RENTAL_APPLICATION_SUCCESSFULLY_SUBMITTED } from '~/assets/strings';
import { Button } from '~/components/common/buttons';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { RentalApplicationSubmittedUseCase } from '~/use-cases/rental-applications/rentalApplicationSubmittedUseCase';

export const ApplicationSubmitted = () => {
  const { t } = useLocalization();
  const { execute: submittedRentalApplication } = useUseCase(RentalApplicationSubmittedUseCase);

  return (
    <>
      <div class="flex h-section3 flex-col bg-white p-6">
        <div class="-mt-10 flex size-full flex-col items-center justify-center text-center">
          <img src={IconCheckEmail} />
          <h1 class="my-4 w-3/5 text-base font-semibold text-text-level02 md:text-lg">{t(RENTAL_APPLICATION_SUCCESSFULLY_SUBMITTED)}</h1>
          <p class="text-xs font-normal text-text-level03 md:text-sm">{t(RENTAL_APPLICATION_NOTIFICATION)}</p>
        </div>
      </div>
      <div class="sticky bottom-0 left-0 w-full border-t border-gray-200 bg-white p-4">
        <div class="flex items-end justify-end">
          <Button id="rental-done-button" onClick={() => submittedRentalApplication()}>
            {t(DONE)}
          </Button>
        </div>
      </div>
    </>
  );
};
