import { rentalApplicationGateway } from '~/gateways/rentalApplicationGateway';
import { RentalApplicationCredentials } from '~/state/mainAppState';
import { GoToNextStepRequest } from '~/types/GoToNextStepRequest';
import { RentalApplicationSteps } from '~/types/RentaApplicationSteps';
import { applicantInfoErrors, RentalApplicationErrorCode } from '~/types/RentalApplication';
import { MagicUseCase } from '../magicUseCase';
import { SaveRentalApplicationUseCase } from './application-steps/saveRentalApplicationUseCase';
import { SetCurrentStepUseCase } from './application-steps/setCurrentStepUseCase';

export class GoToNextStepUseCase extends MagicUseCase {
  private static hasApplicantInfoErrors(errors: RentalApplicationErrorCode[]): boolean {
    return errors.some((error) => applicantInfoErrors.includes(error));
  }

  protected async runLogic(request: GoToNextStepRequest) {
    let application = this.getState().user.rentalApplication.application;
    let credentials: RentalApplicationCredentials = request.credentials;
    if (!credentials.id || !credentials.password) {
      const newApplication = await rentalApplicationGateway.createApplication();
      application = {
        ...application,
        credentials: newApplication.credentials,
        isDraft: newApplication.isDraft,
        newFiles: newApplication.newFiles,
      };
      credentials = newApplication.credentials;
    }
    this.getState().user.rentalApplication.application = application;
    const pathSection = credentials ? `/${credentials.id}/${credentials.password}` : '';
    if (
      !application.isDraft &&
      !this.getState()?.user.rentalApplication.isPaid &&
      this.getState().user.rentalApplication.settings?.requiresPayment
    ) {
      await new SetCurrentStepUseCase().execute(RentalApplicationSteps.PAYMENT);
      this.navigate(`/rental-applications${pathSection}/payment`);
      return;
    }
    if (application?.isDraft) {
      await new SaveRentalApplicationUseCase().execute(credentials);
    }
    switch (request.currentStep) {
      case RentalApplicationSteps.APPLICANT_INFORMATION: {
        const errors = this.getState().user.rentalApplication.errors || [];
        if (GoToNextStepUseCase.hasApplicantInfoErrors(errors)) {
          break;
        }
        await new SetCurrentStepUseCase().execute(RentalApplicationSteps.POTENTIAL_UNITS);
        this.navigate(`/rental-applications${pathSection}/potential-units`);
        break;
      }
      case RentalApplicationSteps.POTENTIAL_UNITS: {
        await new SetCurrentStepUseCase().execute(RentalApplicationSteps.RENTAL_HISTORY);
        this.navigate(`/rental-applications${pathSection}/rental-history`);
        break;
      }
      case RentalApplicationSteps.RENTAL_HISTORY: {
        await new SetCurrentStepUseCase().execute(RentalApplicationSteps.WORK_HISTORY);
        this.navigate(`/rental-applications${pathSection}/employment-income`);
        break;
      }
      case RentalApplicationSteps.WORK_HISTORY: {
        const questions = this.getState().user.rentalApplication.settings?.questions;
        if (questions && questions.length > 0) {
          await new SetCurrentStepUseCase().execute(RentalApplicationSteps.QUESTIONS);
          this.navigate(`/rental-applications${pathSection}/rental-questions`);
        } else {
          await new SetCurrentStepUseCase().execute(RentalApplicationSteps.TERMS_AND_CONDITIONS);
          this.navigate(`/rental-applications${pathSection}/terms-and-conditions`);
        }
        break;
      }
      case RentalApplicationSteps.QUESTIONS: {
        await new SetCurrentStepUseCase().execute(RentalApplicationSteps.TERMS_AND_CONDITIONS);
        this.navigate(`/rental-applications${pathSection}/terms-and-conditions`);
        break;
      }
      case RentalApplicationSteps.TERMS_AND_CONDITIONS: {
        if (!this.getState()?.user.rentalApplication.isPaid && this.getState()?.user.rentalApplication.settings?.requiresPayment) {
          await new SetCurrentStepUseCase().execute(RentalApplicationSteps.PAYMENT);
          this.navigate(`/rental-applications${pathSection}/payment`);
        } else if (this.getState().user.rentalApplication.settings?.requiresScreening) {
          await new SetCurrentStepUseCase().execute(RentalApplicationSteps.TRANS_UNION_SCREENING);
          this.navigate(`/rental-applications${pathSection}/transunion-connection`);
        } else {
          await new SetCurrentStepUseCase().execute(RentalApplicationSteps.APPLICATION_SUBMITTED);
          this.navigate(`/rental-applications${pathSection}/application-submitted`);
        }
        break;
      }
    }
  }
}
