import { companyUnitListingGateway } from '~/gateways/companyUnitListingGateway';
import { RentalApplicationCredentials } from '~/state/mainAppState';
import { MagicUseCase } from '../../magicUseCase';
import { GetRentalApplicationUseCase } from '../getRentalApplicationUseCase';

export class GetUnitsListingUseCase extends MagicUseCase {
  protected async runLogic(params?: RentalApplicationCredentials) {
    const application = this.getState().user.rentalApplication.application;
    if (!application && params) {
      await new GetRentalApplicationUseCase().execute(params);
    }

    const unitsListing = this.getState().user.unitsListing;

    const unitParams = {
      page: unitsListing?.currentPage + 1,
      pageSize: this.getState().user.unitsListing?.pageSize,
      search: unitsListing.search || '',
    };

    const response = await companyUnitListingGateway.getCompanyUnitsListing(unitParams);
    const prevItems = unitsListing.preSelectUnits?.concat(unitParams.page === 1 ? [] : unitsListing.items);

    let hasUnits = this.getState().user.unitsListing.hasUnits;

    if (!hasUnits) {
      hasUnits = response.items.length > 0;
    }

    this.getState().user.unitsListing = {
      ...unitsListing,
      ...response,
      items: Array.from(new Map(prevItems.concat(response.items).map((item) => [item.id, item])).values()),
      hasUnits,
    };
  }
}
