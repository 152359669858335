import { AppState } from '~/framework/appState';
import { CompanyIdProvider } from '~/gateways/network/companyIdProvider';
import { Annotation } from '~/types/Annotation';
import { CompanyInfo } from '~/types/CompanyInfo';
import { RentalApplicationSteps } from '~/types/RentaApplicationSteps';
import {
  RentalApplicationErrorCode,
  RentalHistoryErrorCode,
  RentalApplication,
  EmploymentHistoryErrorCode,
  TransUnionScreening,
  RentalApplicationSettings,
} from '~/types/RentalApplication';
import { SignablePdf } from '~/types/SignablePdf';
import { StripeInfo } from '~/types/StripeInfo';
import { PaginatedUnits, UnitParams, CompanyUnit, TenantHydratedUnitListingDto } from '~/types/Unit';

export interface PlaidInfo {
  linkToken?: string | null;
  publicToken?: string | null;
}

export interface RentalApplicationCredentials {
  id?: string;
  password?: string;
}

export interface RentalApplicationInfo {
  application: RentalApplication;
  credentials?: RentalApplicationCredentials;
  transUnionScreening?: TransUnionScreening;
  isPaid?: boolean;
  settings?: RentalApplicationSettings;
  errors: RentalApplicationErrorCode[];
  rentalHistoryErrors: RentalHistoryErrorCode[][];
  employmentHistoryErrors: EmploymentHistoryErrorCode[][];
  stripe?: StripeInfo;
  currentStep: RentalApplicationSteps;
}

export enum PaymentErrorCode {
  ACCOUNT_ERROR,
  PAYMENT_TYPE_ERROR,
  PAYMENT_FREQUENCY_ERROR,
  MAXIMUM_AMOUNT_ERROR,
  FIXED_AMOUNT_ERROR,
  START_DATE_ERROR,
  END_DATE_ERROR,
}

export interface SignerState {
  document: SignablePdf;
  signedAnnotations: Set<string>;
  nextAnnotation?: Annotation;
}

export interface User {
  companyInfo?: CompanyInfo;
  unitsListing: PaginatedUnits & {
    search?: string;
    preSelectUnits: CompanyUnit[];
    hasUnits?: boolean;
  };
  rentalApplication: RentalApplicationInfo;
  signerState: SignerState;
  unitParams?: UnitParams;
  pinnedUnits?: PaginatedUnits;
  companyUnitsListing?: PaginatedUnits;
  prevCompanyUnitsListingItems?: CompanyUnit[];
  companyUnitListingDetail?: TenantHydratedUnitListingDto;
}

const initialRentalApplicationInfo: RentalApplicationInfo = {
  application: {
    credentials: {},
    newFiles: [],
  },
  credentials: undefined,
  transUnionScreening: undefined,
  isPaid: false,
  errors: [],
  rentalHistoryErrors: [],
  employmentHistoryErrors: [],
  stripe: undefined,
  currentStep: RentalApplicationSteps.APPLICANT_INFORMATION,
};

export class MainAppState extends AppState<User> implements CompanyIdProvider {
  user: User = {
    companyUnitListingDetail: undefined,
    prevCompanyUnitsListingItems: undefined,
    unitParams: undefined,
    pinnedUnits: undefined,
    companyUnitsListing: undefined,
    unitsListing: {
      items: [],
      preSelectUnits: [],
      currentPage: 0,
      pageSize: 10,
      totalCount: 0,
      totalPages: 0,
    },
    rentalApplication: JSON.parse(JSON.stringify(initialRentalApplicationInfo)),
    signerState: {
      document: {
        id: '',
        leaseName: '',
        pages: [],
        signerInitials: '',
        signerName: '',
      },
      signedAnnotations: new Set<string>(),
    },
  };

  public getState(): User {
    return { ...this.user };
  }

  public initializeRentalApplication(): User {
    return (this.user.rentalApplication = JSON.parse(JSON.stringify(initialRentalApplicationInfo)));
  }

  public getCompanyId = (): string | undefined => {
    return this.user.companyInfo?.id;
  };
}

export const mainAppState = new MainAppState();
