import { createErrorFromResponse, FailToFetchError, getContentFromResponse } from '@magicdoor/errors';
import { MagicRequest } from './network/magicRequest';
import { NetworkManager } from './network/networkManager';
export class Gateway {
  protected networkManager: NetworkManager;

  constructor(networkManager: NetworkManager) {
    this.networkManager = networkManager;
  }

  protected sendRequest = async (request: MagicRequest): Promise<Response> => {
    const response = await this.networkManager.sendRequest(request).catch(() => {
      throw new FailToFetchError();
    });
    if (response.ok) {
      return response;
    }

    throw createErrorFromResponse(response.status, await getContentFromResponse(response));
  };

  protected createCommonHeaders = (): Headers => {
    const headers: Headers = new Headers();
    headers.append('content-type', 'application/json;charset=UTF-8');
    return headers;
  };
}
