import { useParams } from '@solidjs/router';
import { For, createEffect, createMemo, createSignal, onCleanup, Accessor, Show } from 'solid-js';
import IconBathroom from '~/assets/images/home/bathroom.svg';
import IconArea from '~/assets/images/home/ft.svg';
import IconRoom from '~/assets/images/home/room.svg';
import IconAc from '~/assets/images/property/ac.svg';
import IconDate from '~/assets/images/property/date.svg';
import IconOutdoor from '~/assets/images/property/outdoor.svg';
import IconParking from '~/assets/images/property/parking.svg';
import IconUtilities from '~/assets/images/property/utilities.svg';
import {
  CONTACT_MANAGER,
  AC,
  PARKING,
  UTILITIES_INCLUDED,
  OUTDOOR,
  LISTED,
  SQFT,
  MONTH,
  SUBMIT_A_RENTAL_APPLICATION,
  OVERVIEW,
  HOME_HIGHLIGHTS,
  FACTS_AND_FEATURES,
  LOCAL_INFORMATION,
  PROPERTY_DETAIL,
  PROPERTIES,
} from '~/assets/strings';
import GoogleMap from '~/components/common/GoogleMap';
import { Button } from '~/components/common/buttons';
import { AreaBreadcrumb } from '~/components/ui';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useStyling } from '~/global-contexts/CompanyStyling';
import { useLocalization } from '~/hooks/useLocalization';
import { useTitle } from '~/hooks/useTitle';
import { PhotoWall } from '~/pages/properties/photo-wall';
import { CompanyStylePresenter } from '~/presenters/CompanyStylePresenter';
import { UnitListingDetailPresenter } from '~/presenters/UnitListingDetailPresenter';
import { GetCompanyUnitListingDetailUseCase } from '~/use-cases/company-unit-listing/getCompanyUnitListingDetailUseCase';
import { CreateRentalApplicationUseCase } from '~/use-cases/rental-applications/createRentalApplicationUseCase';

interface HomeHighlight {
  icon: string;
  title: string;
  data?: string;
}

export default function PropertyDetail() {
  const { t } = useLocalization();
  const { id } = useParams();
  const { companyStyling } = useStyling();
  const { execute: getListingDetail } = useUseCase(GetCompanyUnitListingDetailUseCase);
  const { model: listingDetail } = usePresenter(UnitListingDetailPresenter);
  const { execute: createRentalApplication } = useUseCase(CreateRentalApplicationUseCase);
  const { model: companyInfo } = usePresenter(CompanyStylePresenter);

  const [showStickyDetails, setShowStickyDetails] = createSignal<boolean>(false);
  const [showMobileSticky, setShowMobileSticky] = createSignal<boolean>(false);
  let footerRef: HTMLDivElement | undefined;

  useTitle({ title: t(PROPERTY_DETAIL) });

  const description = createMemo(() => {
    const listingDetailResult = listingDetail();

    const currentLanguage = companyInfo?.()?.currentLanguage;

    return (
      listingDetailResult?.descriptionTranslations?.find((item) => item.language === currentLanguage)?.value ??
      listingDetailResult?.description
    );
  });

  createEffect(() => {
    getListingDetail(id);
  });

  const onCreateRentalApplication = async () => {
    await createRentalApplication(id);
  };

  const highlights: Accessor<HomeHighlight[]> = createMemo(() => {
    return [
      {
        icon: IconAc,
        title: t(AC),
        data: CONTACT_MANAGER,
      },
      {
        icon: IconParking,
        title: t(PARKING),
        data: CONTACT_MANAGER,
      },
      {
        title: t(UTILITIES_INCLUDED),
        data: CONTACT_MANAGER,
        icon: IconUtilities,
      },
      {
        title: t(OUTDOOR),
        icon: IconOutdoor,
        data: CONTACT_MANAGER,
      },
      {
        title: t(LISTED),
        icon: IconDate,
        data: listingDetail()?.listed,
      },
    ];
  });

  createEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = window.innerWidth >= 768 ? 700 : 400;
      setShowStickyDetails(scrollPosition > threshold);

      if (footerRef) {
        const footerRect = footerRef.getBoundingClientRect();
        const isFooterVisible = footerRect.top <= window.innerHeight;
        setShowMobileSticky(scrollPosition > 0 && !isFooterVisible);
      } else {
        setShowMobileSticky(scrollPosition > 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    onCleanup(() => window.removeEventListener('scroll', handleScroll));
  });

  return (
    <div class="flex w-full flex-col items-center pt-10 sm:pt-2">
      <div class="flex h-sixty w-full items-center justify-center bg-border-level01">
        <div class="flex w-full max-w-container items-center justify-center px-4 sm:px-6">
          <AreaBreadcrumb showBack={true} data={[{ name: t(PROPERTIES), path: '/properties' }, { name: t(PROPERTY_DETAIL) }]} />
        </div>
      </div>

      <div class="mt-[30px] w-full max-w-container px-4 sm:mt-[40px] md:mt-[50px] md:px-6">
        <PhotoWall data={listingDetail()?.images || []} />

        <div class="flex flex-col gap-4 lg:flex-row">
          <div class="w-full lg:w-[875px]">
            <div class="mt-6 sm:mt-8">
              <div class="flex-1 border-b border-[#E5E6E7] pb-6 sm:pb-8">
                <div>
                  <span class="text-2xl font-semibold sm:text-[32px]">{listingDetail()?.price}</span>
                  <span class="text-[#5E6987]">/{MONTH}</span>
                </div>

                <div class="mt-2 text-2xl font-semibold leading-normal sm:mt-[10px] sm:text-[40px]">{listingDetail()?.name}</div>
                <div class="mt-1 text-sm text-[#5E6987] sm:mt-[2px]">{listingDetail()?.displayAddress}</div>
                <div class="mt-3 flex flex-wrap items-center gap-4 text-sm text-[#4F5971] sm:mt-[10px]">
                  <div class="flex items-center gap-1">
                    <img src={IconRoom} alt="" class="size-4 sm:size-5" />
                    {listingDetail()?.bedrooms}
                  </div>
                  <div class="flex items-center gap-1">
                    <img src={IconBathroom} alt="" class="size-4 sm:size-5" />
                    {listingDetail()?.bathrooms}
                  </div>
                  <div class="flex items-center gap-1">
                    <img src={IconArea} alt="" class="size-4 sm:size-5" />
                    {listingDetail()?.area} {t(SQFT)}
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-6 w-full sm:mt-[42px]">
              <div class="text-2xl font-semibold text-[#222735] sm:text-[32px]">{t(OVERVIEW)}</div>
              <div class="mt-3 text-sm leading-6 text-[#222735] sm:mt-4 sm:text-[14px] sm:leading-7">{description()}</div>

              <div class="mt-6 rounded-xl border border-[#E8E9EA] sm:mt-[26px]">
                <div class="border-b border-[#E8E9EA] p-4 text-base font-semibold text-[#222735] sm:px-6 sm:py-[18px] sm:text-[18px]">
                  {t(HOME_HIGHLIGHTS)}
                </div>
                <div class="grid grid-cols-1 gap-4 p-4 sm:grid-cols-2 sm:px-6 sm:py-[20px]">
                  <For each={highlights()}>
                    {(item) => (
                      <div class="flex items-center">
                        <div class="flex w-120px items-center gap-2 sm:w-[150px]">
                          <img src={item.icon} alt="" class="size-4 sm:size-5" />
                          <div class="text-xs text-[#7F89A2] sm:text-sm">{item.title}</div>
                        </div>
                        <div class="flex-1 text-left text-sm">{t(item.data)}</div>
                      </div>
                    )}
                  </For>
                </div>
              </div>

              <Show when={listingDetail()?.hasFeatures}>
                <div class="mt-6 rounded-xl border border-[#E8E9EA] sm:mt-[26px]">
                  <div class="border-b border-[#E8E9EA] p-4 text-base font-semibold text-[#222735] sm:px-6 sm:py-[18px] sm:text-[18px]">
                    {t(FACTS_AND_FEATURES)}
                  </div>
                  <div class="flex flex-col gap-4 p-4 sm:gap-6 sm:p-[22px]">
                    <For each={listingDetail()?.features}>
                      {(item) => (
                        <Show when={item.children?.length}>
                          <div>
                            <div class="text-sm font-semibold">{t(item.name)}</div>
                            <div class="grid grid-cols-2 gap-3 p-4 sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-[20px]">
                              <For each={item.children}>
                                {(child) => (
                                  <div class="flex items-center gap-2">
                                    <div class="size-1 rounded-full bg-[#222735]" />
                                    <div class="text-xs text-[#222735] sm:text-sm">{child}</div>
                                  </div>
                                )}
                              </For>
                            </div>
                          </div>
                        </Show>
                      )}
                    </For>
                  </div>
                </div>
              </Show>

              <div class="mt-6 sm:mt-[26px]">
                <div class="mb-2 text-2xl font-semibold sm:text-[32px]">{t(LOCAL_INFORMATION)}</div>
                <Show when={listingDetail()?.address}>
                  <GoogleMap address={listingDetail()?.address ?? ''} />
                </Show>
              </div>

              <Show when={companyStyling?.()?.canReceiveRentalApplications}>
                <div class="mt-4 block lg:hidden">
                  <Button onClick={onCreateRentalApplication} class="w-full bg-primary-color text-center text-white [&>span]:w-full">
                    {t(SUBMIT_A_RENTAL_APPLICATION)}
                  </Button>
                </div>
              </Show>
            </div>
          </div>

          <div class="hidden lg:block">
            <div class="sticky top-1/4 mt-4 h-fit w-[429px] rounded-lg border border-[#E5E6E7] p-6">
              <div
                class={`overflow-hidden transition-all duration-300 ease-in-out ${
                  showStickyDetails() ? 'max-h-[200px] opacity-100' : 'max-h-0 opacity-0'
                }`}>
                <div class="mb-4">
                  <h2 class="text-xl font-semibold">{listingDetail()?.name}</h2>
                  <p class="text-lg font-bold">
                    {listingDetail()?.price} <span class="text-sm text-[#5E6987]">/{MONTH}</span>
                  </p>
                  <p class="text-sm text-[#5E6987]">{listingDetail()?.displayAddress}</p>
                  <div class="mt-2 flex items-center gap-4 text-sm text-[#4F5971]">
                    <div class="flex items-center gap-1">
                      <img src={IconRoom} alt="" />
                      {listingDetail()?.bedrooms}
                    </div>
                    <div class="flex items-center gap-1">
                      <img src={IconBathroom} alt="" />
                      {listingDetail()?.bathrooms}
                    </div>
                    <div class="flex items-center gap-1">
                      <img src={IconArea} alt="" />
                      {listingDetail()?.area} {t(SQFT)}
                    </div>
                  </div>
                </div>
              </div>
              <Show when={companyStyling?.()?.canReceiveRentalApplications}>
                <Button onClick={onCreateRentalApplication} class="w-full bg-primary-color text-center text-white [&>span]:w-full">
                  {t(SUBMIT_A_RENTAL_APPLICATION)}
                </Button>
              </Show>
            </div>
          </div>
        </div>
      </div>

      <div ref={footerRef} class="h-1 w-full" />

      <Show when={showMobileSticky() && companyStyling?.()?.canReceiveRentalApplications}>
        <div class="fixed bottom-0 left-0 w-full bg-white p-4 shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)] lg:hidden">
          <Button onClick={onCreateRentalApplication} class="w-full bg-primary-color text-center text-white [&>span]:w-full">
            {t(SUBMIT_A_RENTAL_APPLICATION)}
          </Button>
        </div>
      </Show>
    </div>
  );
}
