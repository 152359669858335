import Icon404 from '~/assets/errors/404.png';
import { COMPANY_NOT_FOUND, PAGE_NOT_FOUND } from '~/assets/strings';
import { useLocalization } from '~/hooks/useLocalization';

export default function NotFound() {
  const { t } = useLocalization();

  return (
    <>
      <main class="mx-auto flex flex-col gap-10 text-center text-gray-700 md:gap-20">
        <div class="flex w-full justify-center bg-[#F5FAFD] pt-20 md:pt-0">
          <img src={Icon404} />
        </div>
        <div class="flex flex-col items-center justify-center gap-2 text-text-level02">
          <h1 class="text-2xl font-semibold uppercase md:text-4xl">{t(PAGE_NOT_FOUND)}</h1>
          <div class="flex flex-col gap-2 px-2 text-xs md:text-base">
            <p>{t(COMPANY_NOT_FOUND)}</p>
          </div>
        </div>
      </main>
    </>
  );
}
