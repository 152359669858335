import * as Sentry from '@sentry/solid';
import { solidRouterBrowserTracingIntegration } from '@sentry/solid/solidrouter';
import { mount, StartClient } from '@solidjs/start/client';
import { DEV } from 'solid-js';

import 'core-js/features/object/has-own';

if (!DEV) {
  Sentry.init({
    dsn: 'https://7ced882a2f116263d1fdbfcc01db2f68@sentry.magicdoor.io/2',
    integrations: [solidRouterBrowserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

mount(() => <StartClient />, document.getElementById('app') as HTMLDivElement);
