import { NotFoundError } from '@magicdoor/errors';
import { query, redirect } from '@solidjs/router';
import { companyGateway } from '~/gateways/companyInfoGateway';
import { presentCompanyInfo, fallbackInfo } from '~/presenters/CompanyStylePresenter';
import { getCurrentHostName, getRequestURL } from '~/utils/host';

export const queryCompanyInfo = query(async () => {
  try {
    const companyInfo = await companyGateway.getCompanyInfo(getCurrentHostName());

    if (!import.meta.env.DEV && companyInfo.customDomain) {
      const currentURL = getRequestURL();
      if (currentURL && currentURL?.hostname !== companyInfo.customDomain) {
        currentURL.hostname = companyInfo.customDomain;
        return redirect(currentURL.toString(), 307);
      }
    }

    return Object.assign({}, fallbackInfo, presentCompanyInfo(companyInfo));
  } catch (error) {
    console.log('Error loading company info:', error);
    if (error instanceof NotFoundError) {
      throw redirect('/company-not-found');
    }

    return fallbackInfo;
  }
}, 'companyInfo');
