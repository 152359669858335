import { useNavigate } from '@solidjs/router';
import { For, Switch, Match, createMemo, Show } from 'solid-js';
import { createStore } from 'solid-js/store';
import { SEARCH, HOME_TYPE, SELECT_HOME_TYPE, PRICE, SELECT_PRICE, MOVE_IN_DATE, SELECT_MOVE_IN_DATE } from '~/assets/strings';
import { Select, IconSearch, DatePicker, SelectOptions } from '~/components/ui';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { CompanyUnitSearchParamsPresenter } from '~/presenters/CompanyUnitSearchParamsPresenter';
import { UpdateListingSearchParamsUseCase } from '~/use-cases/company-unit-listing/updateListingSearchParamsUseCase';
import { cn } from '~/utils/classnames';

interface SearchTabProps {
  class?: string;
}

interface SearchParams {
  Price?: string;
  propertyTypes?: string[];
  availableFrom?: string;
}

enum SearchComponentType {
  SELECT,
  DATE,
  CHECKBOX,
}

export const SearchTab = (props: SearchTabProps) => {
  const { t } = useLocalization();
  const [searchParams, setSearchParams] = createStore<SearchParams>({});
  const { execute: updateParams } = useUseCase(UpdateListingSearchParamsUseCase);
  const { model: searchConfig } = usePresenter(CompanyUnitSearchParamsPresenter);
  const navigate = useNavigate();

  const templates = createMemo(() => [
    {
      label: t(PRICE),
      type: SearchComponentType.SELECT,
      placeholder: t(SELECT_PRICE),
      value: searchParams.Price,
      options: searchConfig()?.priceRangeOptions ?? [],
      onChange: (value: string) => setSearchParams('Price', value),
    },
    {
      label: t(HOME_TYPE),
      multiple: true,
      type: SearchComponentType.SELECT,
      placeholder: t(SELECT_HOME_TYPE),
      value: searchParams.propertyTypes,
      onChange: (value: string) => setSearchParams('propertyTypes', value),
      options: searchConfig()?.homeTypeOptions ?? [],
    },
    {
      label: t(MOVE_IN_DATE),
      type: SearchComponentType.DATE,
      placeholder: t(SELECT_MOVE_IN_DATE),
      value: searchParams.availableFrom,
      onChange: (value: string | null) => setSearchParams('availableFrom', value ?? ''),
    },
  ]);

  const handleSearch = () => {
    const priceRange = Array.isArray(searchParams.Price) ? searchParams.Price[0]?.split('-') : searchParams.Price?.split('-');

    updateParams({
      minRentalPrice: priceRange?.[0] ?? '',
      maxRentalPrice: priceRange?.[1] ?? '',
      availableFrom: searchParams.availableFrom ?? '',
      propertyTypes: searchParams.propertyTypes ?? [],
    });
    navigate('/properties');
  };

  return (
    <div class={cn('w-full', props.class)}>
      <div
        class="flex w-full flex-col rounded-lg bg-white shadow-[0px_8px_16px_0px_#00000014]
                  md:h-[102px] md:flex-row md:items-center md:overflow-hidden">
        <div class="flex flex-1 flex-col gap-4 p-10 md:flex-row md:justify-start md:py-0 lg:gap-10 lg:px-[70px]">
          <For each={templates()}>
            {(template, index) => (
              <div class="flex flex-col md:flex-row md:items-center">
                <div class="w-full">
                  <div class="mb-[6px] text-sm text-text-level01">{template.label}</div>
                  <Switch>
                    <Match when={template.type === SearchComponentType.SELECT}>
                      <Select
                        id="home-search-input"
                        multiple={template.multiple}
                        onChange={template.onChange}
                        value={template.value as string}
                        class="w-full border-none bg-inputbox-bg"
                        options={template.options as SelectOptions<string>}
                        placeholder={template.placeholder}
                      />
                    </Match>
                    <Match when={template.type === SearchComponentType.DATE}>
                      <DatePicker
                        id="home-date-picker"
                        onChange={(value) => template.onChange(value ?? '')}
                        value={template.value as string}
                        class="w-full border-none"
                      />
                    </Match>
                  </Switch>
                </div>

                <Show when={index() !== templates().length - 1}>
                  <div class="hidden md:ml-[23px] md:block md:h-[44px] md:w-px md:bg-border-level01" />
                </Show>
              </div>
            )}
          </For>
        </div>

        <div
          id="home-search-button"
          onClick={handleSearch}
          class="flex h-[50px] w-full cursor-pointer items-center justify-center gap-1 rounded-b-lg bg-primary-color
                 text-[20px] text-white transition-colors hover:bg-hover-color md:h-full
                 md:w-[196px] md:rounded-none">
          <IconSearch />
          <span>{t(SEARCH)}</span>
        </div>
      </div>
    </div>
  );
};
