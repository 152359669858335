import { getRequestEvent, isServer } from 'solid-js/web';

const TEST_HOST_NAME = 'hogwarts.with.magicdoor.com';

export const getRequestURL = () => {
  if (isServer) {
    const event = getRequestEvent();
    if (event) {
      return new URL(event.request.url);
    }
    throw new Error('No request event found');
  }
};

export const getCurrentHostName = () => {
  if (import.meta.env.DEV) {
    return TEST_HOST_NAME;
  }

  return getRequestURL()?.hostname || window.location.hostname;
};
